<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <iframe width="100%"
                                        height="60%"
                                        v-if="source"
                                        :style="{'min-height':'500px', 'border':'none'}"
                                        :src="source"
                                ></iframe>

                                <template v-if="!success && dataInfo.status=='default'">
                                    <b-row v-for="(field,index) in dataInfo.sign_fields"
                                           :key="field.id"
                                    >
                                        <b-col>
                                            <b>Signature {{ index + 1 }}:</b>
                                            <vueSignature
                                                :ref="'signature'+field.id"
                                                :sigOption="option"
                                                :w="'300px'"
                                                :h="'70px'"
                                                style="border: solid 1px black"
                                                @endStroke="signed"
                                            ></vueSignature>
                                            <font-awesome-icon
                                                @click="undo(field.id)"
                                                icon="undo"
                                                class="mr-2"
                                            />
                                            <font-awesome-icon
                                                @click="clear(field.id)"
                                                icon="trash"
                                                class="mr-2"
                                            />
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <b-button :disabled="!documentSigned"
                                                      variant="primary"
                                                      block
                                                      @click="save"
                                            >Sign
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
import vueSignature from "vue-signature";

export default {
    name: 'Sign',
    components: {
        vueSignature,
    },
    data() {
        return {
            source: null,
            dataInfo: null,
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            documentSigned: false,
            disabled: false,
            success: false,
        }
    },
    mounted() {
        this.$root.$children[0].loading = false

        window.axios.get(window.apiUrl + '/sign-contract/' + this.$route.params.hash)
            .then(response => {
                this.dataInfo = response.data

                this.source = 'data:application/pdf;base64,' + response.data.content
            })
    },
    methods: {
        signed() {
            let isSigned = null
            Object.keys(this.dataInfo.sign_fields).forEach(key => {
                if (this.$refs['signature' + this.dataInfo.sign_fields[key].id][0].isEmpty()) {
                    isSigned = false
                } else {
                    if (isSigned == null) {
                        isSigned = true
                    }
                }
            })

            this.documentSigned = isSigned

        },
        save() {
            let sendData = {}
            Object.keys(this.dataInfo.sign_fields).forEach(key => {
                sendData[this.dataInfo.sign_fields[key].id] = this.$refs['signature' + this.dataInfo.sign_fields[key].id][0].save()
            });
            window.axios.post(window.apiUrl + '/sign-contract/' + this.$route.params.hash, sendData, {'skip_loading': true})
                .then(response => {
                    if (response.data.status == 'done') {
                        this.success = true

                        this.source = 'data:application/pdf;base64,' + response.data.content
                    }
                })
        },
        clear(id) {
            var _this = this;
            _this.$refs['signature' + id][0].clear();
            this.signed()
        },
        undo(id) {
            var _this = this;
            _this.$refs['signature' + id][0].undo();
            this.signed()
        },
    }
}
</script>

<style lang="scss">
body {
    //background-image: url('./../../assets/web_background-erika.jpeg');
    //background-repeat: no-repeat;
    //background-size: cover;
}

.c-app {
    background-color: transparent;
}
</style>